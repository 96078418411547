import React, { Component } from 'react';
import { RecipeDetailsMainContainer, RecipeDetailsContainer, FeaturedContainer, AboutRecipe, RecipeBody, RecipeInstructionSection, VideoWrapper, RecipeInfo, RecipeInfoCards, RecipeTitle, /*StyledTabs*/ } from './styles';
// import { Tab } from "react-bootstrap";
import RecipeIngredients from './RecipeIngredients';
import PropTypes from "prop-types";
import RecipeDirections from './RecipeDirections';
import { connect } from "react-redux";
import { getRecipeDetailsById, updateUserGlobalPoints, getRecommendedRecipeDetails, getFavoriteRecipes, getFeaturedRecipes } from "../../../redux/actions";
import isEmpty from 'lodash/isEmpty';
import Waiting from '../../Waiting';
import { toast } from 'react-toastify';
import AdventurePointDashBoard from '../../AdventurePointDashBoard/index';
import { TitleContainer } from '../../Article/styles';
import { VideoContainerNew } from '../../VideoArticle/styles';
import ReactPlayer from 'react-player';
import { Circle, Tick, CircleContainerNew } from '../../Journeys/styles';
import { ImageContainerIcons, AdminButtons } from '../../Article/styles';
import { withTranslation } from 'react-i18next';
import {TabsStyle, TabsWrapper} from '../../common/commonStyles';
class RecipeDetails extends Component {
  constructor() {
    super();
    this.state = {
      save: 0,
      like: 0,
      imagestatus: 0,
      tabValue:1
    }
  }
  componentDidMount() {
    const { location: { pathname }, getRecipeDetailsById } = this.props;
    const StringArray = pathname.split('/');
    getRecipeDetailsById(StringArray[(StringArray.length - 1)]);
  }
  componentDidUpdate(prevProps) {
    const { location, getRecipeDetailsById } = this.props;
    const StringArray = location.pathname.split('/');
    if (location.pathname !== prevProps.location.pathname) {
      if (StringArray[(StringArray.length - 1)] != '') {
        getRecipeDetailsById(StringArray[(StringArray.length - 1)]);
      }
    }
  }
  navigateEdit = () => {
    const { history, recipeDetailsById: { recipe_details: recipeDetails } } = this.props;
    if (recipeDetails && recipeDetails.length === 0 && !recipeDetails['id']) {
      toast.error('Recipe is not found');
      history.push(`/education/recipes`);
    }
    else {
      history.push(`/education/recipes/${recipeDetails['id']}/edit`);
    }
  };

  onSave = () => {
    this.setState({
      save: !this.state.save,
    })
  };

  addLike = () => {
    this.setState({
      like: !this.state.like,
    })
  };

  addLikes = (journeys) => {
    const { onClickFavoriteRibbon } = this.props;
    onClickFavoriteRibbon("recipe", journeys.id, journeys.like_status ? 0 : 1, journeys.favorite_status, 1)
    this.setState({
      like: !this.state.like,
      imagestatus: journeys.like_status===1?1:0
    })

  };

  saveImage = () => {
    this.setState({
      save: !this.state.save,
    })
  }

  callBack = () => {
    const { recipeDetailsById, getRecipeDetailsById, getRecommendedRecipeDetails, getFavoriteRecipes, getFeaturedRecipes } = this.props;
    const recipeDetails = recipeDetailsById['recipe_details'];
    getRecipeDetailsById(recipeDetails['id']);
    getRecommendedRecipeDetails();
    getFavoriteRecipes(10);
    getFeaturedRecipes();
  }

  updateUserPoints = () => {
    const { updateUserGlobalPoints, recipeDetailsById, } = this.props;
    const recipeDetails = recipeDetailsById['recipe_details'];
    updateUserGlobalPoints(recipeDetails['id'], 23, this.callBack);
  };

  onChangeTabs = (value) =>{
    this.setState({
      tabValue: value
    });
  }

  render() {
    const { history, recipeDetailsById, recipeLoading, updateUserGlobalPoints, updateRecipe, onClickFavoriteRibbon, t } = this.props;
    const { save, like,imagestatus, tabValue } = this.state;

    if (isEmpty(recipeDetailsById) || recipeLoading) {
      return <Waiting />;
    }
    const recipeDetails = recipeDetailsById['recipe_details'];
    const selectedCookTimeOption = recipeDetails && recipeDetails['cook_time_option'];
    return (
      <RecipeDetailsMainContainer style={{ "margin-top": '-110px' }}>
        {
          recipeDetails ? (
            <RecipeDetailsContainer>
              <TitleContainer float={'right'}>
                <div>
                  <span >{recipeDetails['name']}</span>
                </div>
                <div>
                  <div>{recipeDetails['title']}</div>
                  <div>
                    {save !== 0 || recipeDetails.favorite_status ?
                      <img src="/public/images/NewDashboardV2/ribbonColor.png" onClick={() => { onClickFavoriteRibbon("recipe", recipeDetails.id, 0, recipeDetails.favorite_status ? 0 : 1, 0); this.saveImage() }} /> :
                      <img src="/public/images/NewDashboardV2/ribbonNew.png" onClick={() => { onClickFavoriteRibbon("recipe", recipeDetails.id, 0, recipeDetails.favorite_status ? 0 : 1, 0); this.saveImage() }} />
                    }
                  </div>
                </div>
              </TitleContainer>
              <VideoWrapper>
                <VideoContainerNew margin={'15px'}>
                  <ReactPlayer
                    className="react-player"
                    url={recipeDetails['video_url']}
                    onEnded={this.updateUserPoints}
                    controls
                  />
                </VideoContainerNew>
                <ImageContainerIcons padding={'15px'}>
                  <div>
                    <div>
                      {!imagestatus && (like || recipeDetails.like_status)? <img src="/public/images/NewDashboardV2/ColorLike.png" alt="like" onClick={() => this.addLikes(recipeDetails)} />:<img src="/public/images/NewDashboardV2/like.png" alt="like" onClick={() => this.addLikes(recipeDetails)} /> }
                      {<span>{recipeDetails['likes_count']}</span>}
                      {/* {like ? <span>{recipeDetails.like_status ? (recipeDetails['likes_count']) - 1:(recipeDetails['likes_count']) + 1}</span>: <span>{recipeDetails['likes_count']}</span>} */}
                    </div>

                    <div>
                      <img src="/public/images/NewDashboardV2/view.png" alt="view" />
                      <span>{recipeDetails['seen_count']}</span>
                    </div>
                  </div>
                  <div >
                    <CircleContainerNew style={{ "marginLeft": "15px" }}>
                      <div className="flex">
                        <Circle borderColor="#9c9c9c" checked={recipeDetails['view_status']}>
                          <Tick checked={recipeDetails['view_status']} />
                        </Circle>
                      </div>
                      <div className="quiz">
                        {t("Watched")}
                      </div>
                    </CircleContainerNew>
                  </div>
                </ImageContainerIcons>
              </VideoWrapper>
              <AboutRecipe>
                <RecipeBody>
                  <div className="recipeHeader">{t("About the recipe")}:</div>
                  <div className="recipeDescription">{recipeDetails['description']}</div>
                </RecipeBody>

                <RecipeInfo>
                  <RecipeTitle>{t("Recipe Information")}:</RecipeTitle>
                  <RecipeInfoCards>
                    <div> <img src="/public/images/NewDashboardV2/schef.png" alt="view" />
                    </div>
                    <div><span>{recipeDetails['level']}</span>
                    </div>
                  </RecipeInfoCards>

                  <RecipeInfoCards>
                    <div> <img src="/public/images/NewDashboardV2/serve.png" alt="view" />
                    </div>
                    <div><span>{recipeDetails['servings']}</span>
                    </div>
                  </RecipeInfoCards>
                  <RecipeInfoCards>
                    <div> <img src="/public/images/NewDashboardV2/snacks.png" alt="view" />
                    </div>
                    <div><span>{recipeDetails['meal_type']}</span>
                    </div>
                  </RecipeInfoCards>
                  <RecipeInfoCards>
                    <div> <img src="/public/images/NewDashboardV2/prep.png" alt="view" />
                    </div>
                    <div><span>{t("Prep")} :&nbsp;</span><span>{`${recipeDetails['prep_time']}mins`}</span>
                    </div>
                  </RecipeInfoCards>
                  <RecipeInfoCards>
                    <div> <img src="/public/images/NewDashboardV2/cook.png" alt="view" />
                    </div>
                    <div>
                      <span>{t("Cook")} : {selectedCookTimeOption ? recipeDetails['cook_time'] : `${recipeDetails['cook_time']}mins`}</span>
                    </div>
                  </RecipeInfoCards>
                  <RecipeInfoCards>
                    <div> <img src="/public/images/NewDashboardV2/time.png" alt="view" />
                    </div>
                    <div>
                      <span>{t("Total")} :&nbsp;</span><span>{`${recipeDetails['total_time']}mins`}</span>
                    </div>
                  </RecipeInfoCards>
                </RecipeInfo>
              </AboutRecipe>
              <RecipeInstructionSection>
                <TabsWrapper >
                  <TabsStyle  onClick={()=>this.onChangeTabs(1)} active={tabValue===1}>
                    Ingredients
                    {/* <RecipeIngredients ingredients={recipeDetailsById['ingredients']} categoryTagsList={recipeDetailsById['category_tags']} /> */}
                  </TabsStyle>
                  <TabsStyle onClick={()=>this.onChangeTabs(2)} active={tabValue===2}>
                    Steps
                    {/* <RecipeDirections history={history} recipeDetails={recipeDetails} directions={recipeDetailsById['directions']} images={recipeDetailsById['recipe_images']} updateUserGlobalPoints={updateUserGlobalPoints} ingredients={recipeDetailsById['ingredients']} categoryTagsList={recipeDetailsById['category_tags']} /> */}
                  </TabsStyle>
                </TabsWrapper>
                {tabValue===1?<RecipeIngredients ingredients={recipeDetailsById['ingredients']} categoryTagsList={recipeDetailsById['category_tags']} />:
                  <RecipeDirections history={history} recipeDetails={recipeDetails} directions={recipeDetailsById['directions']} images={recipeDetailsById['recipe_images']} updateUserGlobalPoints={updateUserGlobalPoints} ingredients={recipeDetailsById['ingredients']} categoryTagsList={recipeDetailsById['category_tags']} />}
              </RecipeInstructionSection>
            </RecipeDetailsContainer>
          ): null
        }
        <FeaturedContainer width={"32%"} padding={"25"}>
          {updateRecipe &&
            <AdminButtons background={'#9FC989'} onClick={this.navigateEdit}>
              <div>
                <img src="/public/images/NewDashboardV2/editrecipe.png" alt="editrecipe" />
              </div>
              <div>
                {t("Edit Video")}
              </div>
            </AdminButtons>
          }
          <AdventurePointDashBoard />
        </FeaturedContainer>
      </RecipeDetailsMainContainer>
    );
  }
}
RecipeDetails.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  getRecipeDetailsById: PropTypes.func,
  recipeDetailsById: PropTypes.object,
  editRecipe: PropTypes.func,
  role: PropTypes.string,
  recipeLoading: PropTypes.bool,
  updateUserGlobalPoints: PropTypes.func.isRequired,
  updateRecipe: PropTypes.bool,
  onClickFavoriteRibbon: PropTypes.func,
  getRecommendedRecipeDetails: PropTypes.func,
  getFavoriteRecipes: PropTypes.func,
  getFeaturedRecipes: PropTypes.func,
  t: PropTypes.func
};
const mapStateToProps = (state) => ({
  recipeDetailsById: state.education.recipeDetailsById,
  recipeLoading: state.education.recipeLoading
});
const mapDispatchToProps = (dispatch) => ({
  getRecipeDetailsById: (id) => dispatch(getRecipeDetailsById(id)),
  updateUserGlobalPoints: (actionId, activityType, callBackForUpdaingApiDetails) => dispatch(updateUserGlobalPoints(actionId, activityType, callBackForUpdaingApiDetails)),
  getRecommendedRecipeDetails: () => dispatch(getRecommendedRecipeDetails()),
  getFavoriteRecipes: (id) => dispatch(getFavoriteRecipes(id)),
  getFeaturedRecipes: () => dispatch(getFeaturedRecipes()),
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecipeDetails));